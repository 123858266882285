import React from "react";
import { graphql } from "gatsby";

import Page from "../../templates/Page";

const ProcurementPage = ({ data }) => {
  return <Page data={data} />;
};

export const pageQuery = graphql`
  query {
    page: markdownRemark(
      frontmatter: { path: { eq: "/offentlig-upphandling" } }
    ) {
      ...pageFragment
    }
  }
`;

export default ProcurementPage;
